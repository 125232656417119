<template>
    <div class="init">
        <el-form :model="fromValue" label-width="100px" class="elForm" :rules="rules" ref='form_' label-position="top">
            <el-form-item :label="$t('curr.text1')" prop="coin">
                <el-input v-model="fromValue.coin" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item label="icon" prop="coinIcon">
                <el-upload class="avatar-uploader"
                    :show-file-list="false" :auto-upload='false'
                    :on-change="beforeAvatarUpload">
                    <img v-if="fromValue.coinIcon" :src="fromValue.coinIcon" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
                    <div class="lineWrite">
                        {{$t('nfts.relus1')}}50px * 50px<br />
                        {{$t('nfts.relus2')}}<br />
                        {{$t('nfts.relus3')}}PNG、JPG、JPEG<br />
                    </div>
            </el-form-item>
            <el-form-item :label="$t('curr.text2')" prop="coinType">
                <el-select v-model="fromValue.coinType" :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                    <el-option v-for="item in coinTypeList" :key="item.value" :label="item.label"
                    :value="parseInt(item.value)" />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('curr.text5')" prop="chain_protocol">
                <el-cascader :teleported='false' v-model="fromValue.chain_protocol" :props="{checkStrictly: true}" :options="chain_protocolArray" @change="handleChange"/>
            </el-form-item>
            <el-form-item :label="$t('curr.text6')" prop="hasTags">
                <el-radio-group v-model="fromValue.hasTags">
                    <el-radio :label="parseInt(item.value)" size="large" border v-for='item in hasTagsArray' :key='item.value'>{{item.label}}</el-radio>
                    <!-- <el-radio :label="1" size="large" border>{{$t('curr.text7')}}</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('curr.text10')" prop="showPrecision">
                <el-input v-model="fromValue.showPrecision" type="number" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item label="Gas Limit" prop="gasLimit">
                <el-input v-model="fromValue.gasLimit" type="number" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('curr.text9')" prop="contractAddr"> 
                <el-input v-model="fromValue.contractAddr" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('curr.text11')" prop="minCollection">
                <el-input v-model="fromValue.minCollection" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('curr.text12')" prop="officialWebsite">
                <el-input v-model="fromValue.officialWebsite" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('curr.text17')" prop="isDeposit">
                <el-radio-group v-model="fromValue.isDeposit">
                    <el-radio :label="1" size="large" border>{{$t('button.open_')}}</el-radio>
                    <el-radio :label="0" size="large" border>{{$t('button.close_')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('curr.text18')" prop="isWithdraw">
                <el-radio-group v-model="fromValue.isWithdraw">
                    <el-radio :label="1" size="large" border>{{$t('button.open_')}}</el-radio>
                    <el-radio :label="0" size="large" border>{{$t('button.close_')}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('curr.text19')" prop="googleCode">
                <el-input v-model="fromValue.googleCode" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    import { Plus } from '@element-plus/icons-vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    import { getDataStyle } from '@/utils/dataBind/parmsView'
    
    const fromValue = reactive({ 
        coinId:'',coin:'',
        coinIcon: '',coinType:1,chain:'',protocol:'',chain_protocol:'',
        hasTags:0,showPrecision:'',gasLimit:'',officialWebsite:'',
        isDeposit: 0,isWithdraw: 0,googleCode:'',minCollection:'',contractAddr:''
    })
    const coinTypeList = ref([]);getDataStyle('coin_type',(e)=>{coinTypeList.value = e})
    const hasTagsArray = ref([]);getDataStyle('has_tags_status',(e)=>{hasTagsArray.value = e})

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const chain_protocolArray = ref([])


    const beforeAvatarUpload = (e)=>{//无感知上传后缀名
        let file = new FormData()
        file.append("file",e.raw)
        Api_.getImgUrl(file).then(res=>{
            if(res.code === 0){
                fromValue.coinIcon = res.data.url
            }
        })
    }
    const changeProtocolType = ()=>{//
        Api_.getCoinOrxie({
            status: 1
        }).then(res=>{
            chain_protocolArray.value = dicFormatter(res.data)
        })
    }
    const dicFormatter = (e)=>{
        let obj_1 = []
        for(let item of e){
            if(item){
                let obj_ = {
                    label: item.chain,
                    value: item.chain,
                }
                if(item.chainProtocolInfos.length > 0){
                    let obj_c = []
                    for(let items of item.chainProtocolInfos){
                        obj_c.push({
                            label: items.protocol,
                            value: items.protocol
                        })
                    }
                    obj_.children = obj_c
                    obj_1.push(obj_)
                }
            }
        }
        return obj_1
    }

    const emit = defineEmits(['successFun'])

    const handleChange = (e)=>{
        fromValue.chain = e[0]
        fromValue.protocol = e[1]
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(fromValue.coinType === 2 && fromValue.contractAddr === ''){
                    ElNotification({
                        message: '币种类型是“代币”，合约地址需必填',
                        type: 'error',
                    })
                    return
                }
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.coinId
                    delete obj_.chain_protocol
                    if(obj_.coinType === 1){
                        delete obj_.protocol
                    }
                    Api_.addCurrencyObj(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    if(fromValue.coinType === 1){
                        fromValue.protocol = ''
                    }
                    Api_.updateCurrencyObj(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            if(fromValue.protocol){
                fromValue.chain_protocol = [fromValue.chain , fromValue.protocol]
            }else{
                fromValue.chain_protocol = [fromValue.chain]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
        changeProtocolType()
    }
    defineExpose({init_,completeFun})
</script>
<style lang="scss" scoped>
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
    .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
    }
    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #ccc;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
    }
    .lineWrite{
        line-height: 20px;
        font-size: 12px;
        margin-left: 16px;
        height: 178px;
        display: flex;
        align-items: flex-end;
    }
</style>