import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
export const fromSearchChain = [{
    type: 'select',
    name: t('chain.text2'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
    isFartherFun: 'protocol',//关联的选项
},{
    type: 'select',
    name: t('agreement.text3'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],   
    props:{
        value:'protocol',
        label:'protocol'
    },
    isRelation:'chain'//目标对象
},]