import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

import { fromSearchChain } from '@/const/publicData/chain'
export const object = [{
    type: 'select',
    name: t('curr.text2'),
    prop: 'coinType',
    isParms: 'coin_type'
},
...fromSearchChain,
{
    type: 'select',
    name:  t('curr.text6'),
    prop: 'hasTags',
    isParms: 'has_tags_status'
},{
    type: 'select',
    name:  t('curr.text16'),
    prop: 'status',
    isParms: 'enable_status'
},{
    type: 'datetime',
    name:  t('curr.text13'),
    prop: 'createTime'
}]