
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    coin: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    coinIcon: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    hasTags: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    showPrecision: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    minCollection: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    gasLimit: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    officialWebsite: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    coinType: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    isDeposit: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    isWithdraw: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    googleCode: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    chain_protocol: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
}
export default{
    mainRules
}