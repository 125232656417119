
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = () => {
    return {
    border: true,
    stripe: true,
    menuAlign: 'center',
    searchMenuSpan: 6,
    height: 'auto',
    align: 'center',
    viewBtn: false,
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    refreshBtn: false,
    menuWidth: 100,
    menu:false,
    column: [{
        width: 130,
        label: t('curr.text1'),
        prop: 'coin',
    },{
        width: 100,
        label: 'Icon',
        prop: 'coinIcon',
    },{
        width: 120,
        label: t('curr.text2'),
        prop: 'coinType',
    }, {
        width: 80,
        label: t('chain.text2'),
        prop: 'chain',
    }, {
        width: 80,
        label: t('agreement.text3'),
        prop: 'protocol',
    },
    {
        width: 130,
        label: t('curr.text6'),
        prop: 'hasTags',
    }, {
        width: 150,
        label: t('curr.text9'),
        prop: 'contractAddr',
    },{
        width: 200,
        label: t('curr.text11'),
        prop: 'minCollection',
    }, {
        width: 130,
        label: t('curr.text10'),
        prop: 'showPrecision',
    }, {
        width: 100,
        label: 'Gas Limit',
        prop: 'gasLimit',
    },  {
        width: 150,
        label: t('curr.text12'),
        prop: 'officialWebsite',
    },
    {
        width: 160,
        label: t('curr.text13'),
        prop: 'createTime',
    },{
        width: 160,
        label: t('curr.text14'),
        prop: 'updateTime',
    }, {
        width: 100,
        label: t('chain.text11'),
        prop: 'updateUser',
    },{
        width: 100,
        label: t('curr.text16'),
        prop: 'status',
        fixed:'right',
    },{
        width: 100,
        label: t('curr.text17'),
        prop: 'isDeposit',
        fixed:'right',
    },{
        width: 100,
        label: t('curr.text18'),
        prop: 'isWithdraw',
        fixed:'right',
    },{
        width: 140,
        label: t("button.operation"),
        prop: 'caozuo',
        fixed:'right',
      }
]
}
}
