<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #coinIcon='scope'>
                <img :src='scope.row.coinIcon' alt='icon' style="width: 50px"/>
            </template>
            <template #status ="scope">
                <el-switch v-model="scope.row.status" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)'
                @change='changeStatus(scope.row,"status")'/>
            </template>  
            <template #isDeposit ="scope">
                <el-switch v-model="scope.row.isDeposit" :inactive-value='parseInt(valueArray1[0].value)' :active-value='parseInt(valueArray1[1].value)'
                @change='changeStatus(scope.row,"isDeposit")'/>
            </template>  
            <template #isWithdraw ="scope">
                <el-switch v-model="scope.row.isWithdraw" :inactive-value='parseInt(valueArray1[0].value)' :active-value='parseInt(valueArray1[1].value)'
                @change='changeStatus(scope.row,"isWithdraw")'/>
            </template>    
            <template #caozuo="scope">
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="openView(1, scope.row)">{{$t('button.update')}} </el-button>
            </template>
            <template #hasTags="scope">
                {{getDataAboutText(16,scope.row.hasTags)}}
            </template>
            <template #coinType="scope">
                {{getDataAboutText(15,scope.row.coinType)}}
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun' />
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/coin/currency";
    import { ElNotification } from 'element-plus'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/coin/currency'
    const object_ = ref(object);
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataAboutText,getDataStyle } from '@/utils/dataBind/parmsView'
    
    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getCurrencyList'])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    import bindView from './bind.vue'
    const bindView_ = ref(null)
    
    const roleManager_btn_add = ref(true)
    const tableOption = ref({}); tableOption.value = tableOption1(false)
    const roleManager_btn_edit = ref(true)
    // const roleManager_btn_del = ref(true)

    const valueArray = ref([]);getDataStyle('enable_status',(e)=>{valueArray.value = e})
    const valueArray1 = ref([]);getDataStyle('isDeposit_or_isWithdraw',(e)=>{valueArray1.value = e})
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm)
        })
    }
    const titFun = (res,status) => {
        listLoading.value = false
        if(res.code === 0){
            ElNotification({
                message: status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                type: 'success',
            })
            getList()
        }
    }
    const changeStatus = (value_,str_)=>{
        if(value_.coinId){
            listLoading.value = true
            if(str_ === 'status'){
                let status = value_.status === 0?0:1
                Api_.updateCurrencyStatus({status,coinId:value_.coinId}).then(res=>{
                    titFun(res,status)
                })
            }else if(str_ === 'isDeposit'){
                let isDeposit = value_.isDeposit === 0?0:1
                Api_.updateCurrencyStatusTo({isDeposit,coinId:value_.coinId}).then(res=>{
                    titFun(res,isDeposit)
                })
            }else{
                let isWithdraw = value_.isWithdraw === 0?0:1
                Api_.updateCurrencyStatusGet({isWithdraw,coinId:value_.coinId}).then(res=>{
                    titFun(res,isWithdraw)
                })
            }
        }
    }
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    getList(1)
</script>
